<template>
  <div class="d-lg-flex flex-lg-row">
    <b-nav
      id="navbarTab"
      class="d-inline-flex"
      :vertical="$store.getters['app/lgAndUp']"
      :tabs="$store.getters['app/mdAndDown']"
    >
      <b-nav-item
        v-for="(tab, index) in filteredTabs"
        :key="tab.name"
        href="javascript:void(0)"
        :active="activeTabIndex === index"
        :disabled="!product.mainVariant || product.mainVariant.id === null"
      >
        <div
          class="text-nowrap"
          @click="switchTab(tab.component, tab.withoutVariantId)"
        >
          <b-button v-if="$store.getters['app/lgAndUp']" :variant="tab.component === activeTab ? 'primary' : ''" class="nav-btn">
            <span>{{ index + 1 }}</span>
          </b-button>
          <span class="step-title">{{ $t(tab.title) }}</span>
        </div>
      </b-nav-item>
    </b-nav>

    <b-card class="d-inline-flex w-100 mb-0" no-body>
      <keep-alive v-if="!product._errors.notFound && showTabs">
        <component
          :is="activeTab"
          @previous-tab="switchTab(filteredTabs[activeTabIndex -1].component)"
          @next-tab="switchTab($event || filteredTabs[activeTabIndex +1].component)"
        />
      </keep-alive>
      <error-404 v-else />
    </b-card>
  </div>
</template>

<script>
import { computed, defineComponent, provide, ref, watch } from '@vue/composition-api'
import { findIndex, kebabCase, omit, snakeCase } from 'lodash'

import Product from '@/models/Product'
import Fuel from '@/models/ProductFuel'
import Hangar from '@/models/ProductHangar'
import Helicopter from '@/models/ProductHelicopter'
import Package from '@/models/ProductPackage'
import Service from '@/models/ProductService'

export default defineComponent({
  name: 'ProductWizard',

  components: {
    MainInformation: () => import('./tab/MainInformation.vue'),
    AdditionalInformation: () => import('./tab/AdditionalInformation.vue'),
    TechnicalInformation: () => import('./tab/TechnicalInformation.vue'),
    GeneralFareManagement: () => import('./tab/GeneralFareManagement.vue'),
    PriceManagement: () => import('./tab/PriceManagement.vue'),
    Airports: () => import('./tab/Airports.vue'),
    ContractPriceManagement: () => import('./tab/ContractPriceManagement.vue'),
    FuelCertificate: () => import('./tab/FuelCertificate.vue'),
    GeneralPriceManagementFuel: () => import('./tab/GeneralPriceManagementFuel.vue'),
    PackageManagement: () => import('./tab/PackageManagement.vue'),
    HelicopterManagement: () => import('./tab/HelicopterManagement.vue'),
    HelicopterAvailability: () => import('./tab/HelicopterAvailability.vue'),
    HelicopterBases: () => import('./tab/HelicopterBases.vue'),
    Error404: () => import('../base/Error404.vue'),
  },

  setup(_props, ctx) {
    // Prevent destructure the ctx.root's value (except function), possibility to lost the reactivity
    const { $router, _cloneDeep } = ctx.root

    const saveLoader = ref(false)

    // For clear cache <keep-alive />
    const showTabs = ref(true)

    const components = [
      { // MAIN INFORMATION
        title: 'service.main_informations.title',
        component: 'main-information',
        serviceType: ['all'],
        withoutVariantId: true,
      },
      { // ADDITIONAL INFORMATIONS
        title: 'service.additional_informations.title',
        component: 'additional-information',
        serviceType: ['all'],
      },
      { // TECHNICAL INFORMATIONS
        title: 'service.technical_informations.title',
        component: 'technical-information',
        serviceType: ['hangar', 'parking', 'outside-ramps'],
      },
      { // PACKAGE MANAGEMENT
        title: 'service.package_service.title',
        component: 'package-management',
        serviceType: ['package', 'mandatory-package'],
      },
      { // HELICOPTER MANAGEMENT
        title: 'service.helicopter.title',
        component: 'helicopter-management',
        serviceType: ['helicopter'],
      },
      { // HELICOPTER Availability
        title: 'service.helicopter.availability.title',
        component: 'helicopter-availability',
        serviceType: ['helicopter'],
      },
      { // HELICOPTER BASES
        title: 'service.helicopter.bases.title',
        component: 'helicopter-bases',
        serviceType: ['helicopter'],
      },
      { // PRICE MANAGEMENT
        title: 'service.price_management',
        component: 'price-management',
        serviceType: ['service', 'hangar', 'parking', 'outside-ramps'],
      },
      { // AIRPORTS
        title: 'service.location.title',
        component: 'airports',
        serviceType: ['service', 'package', 'mandatory-package', 'hangar', 'parking', 'outside-ramps'],
        withoutVariantId: true,
      },
      { // GENERAL FARE MANAGEMENT
        title: 'service.general_fare_management.title',
        component: 'general-fare-management',
        serviceType: ['helicopter'],
      },
      { // GENERAL PRICE MANAGEMENNT FUEL
        title: 'service.fuel.prices.title',
        component: 'general-price-management-fuel',
        serviceType: ['fuel'],
      },
      { // FUEL CERTIFICATE
        title: 'service.fuel_certificate.title',
        component: 'fuel-certificate',
        serviceType: ['fuel'],
      },
      { // CONTRACT PRICE MANAGEMENT
        title: 'service.contract_price_management',
        component: 'contract-price-management',
        serviceType: ['all'],
      },
    ]

    const defaultAttributes = {}
    const getModel = () => {
      switch (ctx.root.$route.params.typology) {
        case 'fuel': return Fuel
        case 'hangar': case 'parking': case 'outside-ramps':
          defaultAttributes.typology = snakeCase(ctx.root.$route.params.typology)
          return Hangar
        case 'helicopter': return Helicopter
        case 'package': case 'mandatory-package': return Package
        case 'service': return Service
        default: return Product
      }
    }

    let Model = getModel()
    const product = ref(new Model(defaultAttributes))
    provide('product', product)

    const activeTab = ref('main-information')
    const activeTabIndex = ref(0)

    const switchTab = (componentName, withoutVariantId, routeName = ctx.root.$route.name) => {
      let nextRouteName = ''
      const query = {}
      const variantId = ctx.root.$route.query.vid || product.value.mainVariant?.id
      if (routeName === 'serviceAdd') {
        nextRouteName = 'productEdit'
      } else {
        nextRouteName = routeName
      }
      if (!withoutVariantId && variantId) query.vid = variantId
      $router.push({
        name: nextRouteName,
        params: {
          service_id: product.value.id,
          typology: ctx.root.$route.params.typology || kebabCase(product.value.typology),
          tab: componentName,
        },
        query,
      })
    }

    // FILTERED TABS DEPEND ON PRODUCT TYPE
    const filteredTabs = computed(() => {
      if (product.value === null) return []
      if (ctx.root.$route.name === 'serviceAdd') return [components[0]]

      return components.filter(cpnt => cpnt.serviceType.includes(ctx.root.$route.params.typology) || cpnt.serviceType[0] === 'all')
    })

    watch(() => ctx.root.$route.name, routeName => {
      if (routeName === 'serviceAdd') {
        product.value = new Product({})
      } else {
        Model = getModel()
        const oldVariant = _cloneDeep(product.value.mainVariant)
        product.value = new Model(omit(product.value._attributes, 'mainVariant'))
        if (oldVariant) {
          product.value.set({ mainVariant: { ...product.value.mainVariant, ...oldVariant } })
        }
      }
    })

    return {
      product,
      showTabs,
      activeTab,
      activeTabIndex,
      switchTab,
      filteredTabs,
      saveLoader,
    }
  },

  watch: {
    '$route.name': {
      handler(routeName) {
        // Clear cache of <keep-alive />
        if (routeName === 'serviceAdd') {
          this.showTabs = false
          this.$nextTick(() => {
            this.showTabs = true
          })
        }
      },
    },
    '$route.params.tab': {
      handler(tabName) {
        this.product.clearErrors()
        this.activeTab = tabName || 'main-information'
        const activeTabIndex = findIndex(this.filteredTabs, tab => tab.component === this.activeTab)

        if (activeTabIndex !== -1) this.activeTabIndex = activeTabIndex
        else this.product.setError('notFound')
      },
      immediate: true,
    },
  },
})
</script>

<style lang="scss">
#navbarTab {
  .nav-item {
    padding: 8px;

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;

      .nav-btn {
        border: none;
        margin-right: 1rem;
        border-radius: 6px;
        width: 2.71rem;
        height: 2.71rem;
      }
    }

    .step-title {
      font-size: 1rem;
      font-weight: 600;
      color: #b8c2cc !important;
    }
    .nav-link.active .step-title {
      color: rgb(220, 193, 129) !important;
    }
  }
}

[dir] body.dark-layout #navbarTab {
  .nav-item {
    border-color: #3b4253 !important;

    .nav-link {
      .btn-secondary {
        background-color: rgba(220, 193, 129, 0.08) !important;
      }
    }
  }
}
</style>
